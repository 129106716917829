import React, { useState, useEffect } from "react";
import "./countdown.css";
import { useTranslation } from "react-i18next";

const CountdownTimer = () => {

  const { t } = useTranslation();

  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const targetDate = new Date("December 13, 2024 00:00:00").getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0) {
        clearInterval(interval);
        setTimeLeft(null); // Time's up
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        const formattedDays = String(days).padStart(2, "0");
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");

        setTimeLeft({ days: formattedDays, hours: formattedHours, minutes: formattedMinutes, seconds: formattedSeconds });
      }
    }, 1000);

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  if (!timeLeft) {
    return <h1>Time's up!</h1>;
  }

  return (
    <div className="countdown-cont">
      <h2>{t("countdownT")}</h2>
      <div className="count-timer">
        <p>
          {timeLeft.days}
          <span>{t("days")}</span>
        </p>
        <p>
          {timeLeft.hours}
          <span>{t("hours")}</span>
        </p>
        <p>
          {timeLeft.minutes}
          <span>{t("minutes")}</span>
        </p>
        <p>
          {timeLeft.seconds}
          <span>{t("seconds")}</span>
        </p>
      </div>
    </div>
  );
};

export default CountdownTimer;
