// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


// Get the persisted language from localStorage or default to 'en'
const savedLanguage = localStorage.getItem('language') || 'en';

// Define translations
const resources = {
    en: {
        translation: {
            home: "Home",
            signup: "Sign up",
            countdownT: "We’re coming in:",
            days: "Days",
            hours: "Hours",
            minutes: "Minutes",
            seconds: "Seconds",
            heroP: "Sign up now and enjoy",
            heroSpan1: "a free movie on us.",
            heroP2: "Only for the first",
            heroP3: "people",
            joinUsP: "Join us today! Create your account and unlock exclusive benefits, rewards, and more. Let’s get started",
            name: "enter full name:",
            phone: "enter phone number:",
            email: "enter email address:",
            emailErr: "Invalid email address.",
            allErr: "Please fill all available fields",
            success: "Congratulations!!! you’ve successfully signed up"
        },
    },
    pt: {
        translation: {
            home: "Lar",
            signup: "Cadastre-se",
            countdownT: "Estamos entrando:",
            days: "Dias",
            hours: "horas",
            minutes: "minutos",
            seconds: "segundos",
            heroP: "Cadastre-se agora e aproveite",
            heroSpan1: "um filme grátis por nossa conta.",
            heroP2: "Somente para o primeiro",
            heroP3: "pessoas",
            joinUsP: "Junte-se a nós hoje! Crie sua conta e desbloqueie benefícios, recompensas exclusivos e muito mais. Vamos começar",
            name: "digite o nome completo:",
            phone: "digite o número do telefone:",
            email: "digite o endereço de e-mail:",
            emailErr: "Endereço de e-mail inválido.",
            allErr: "Por favor preencha todos os campos disponíveis",
            success: "Parabéns!!! você se inscreveu com sucesso"
        },
    },
    fr: {
        translation: {
            home: "Maison",
            signup: "S'inscrire",
            countdownT: "Nous entrons:",
            days: "Jours",
            hours: "heures",
            minutes: "minutes",
            seconds: "secondes",
            heroP: "Inscrivez-vous maintenant et profitez-en",
            heroSpan1: "un film gratuit sur nous.",
            heroP2: "Seulement pour le premier",
            heroP3: "personnes",
            joinUsP: "Rejoignez-nous aujourd'hui ! Créez votre compte et débloquez des avantages exclusifs, des récompenses et bien plus encore. Commençons",
            name: "entrez le nom complet :",
            phone: "entrez le numéro de téléphone :",
            email: "entrez l'adresse e-mail :",
            emailErr: "Adresse e-mail invalide.",
            allErr: "Veuillez remplir tous les champs disponibles",
            success: "Félicitations!!! vous êtes inscrit avec succès"
        },
    },
};

// Initialize i18next
i18n.use(initReactI18next).init({
    resources,
    lng: savedLanguage, // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
        escapeValue: false, // React already escapes
    },
});

export default i18n;
